<template>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path v-if="icon == 'check'" :fill="color" d="M18.2461 40.3715L5.57112 26.4251C4.80963 25.5872 4.80963 24.2287 5.57112 23.3908L8.32877 20.3564C9.09026 19.5185 10.325 19.5185 11.0865 20.3564L19.625 29.7513L37.9135 9.62841C38.675 8.79053 39.9097 8.79053 40.6712 9.62841L43.4289 12.6628C44.1904 13.5006 44.1904 14.8591 43.4289 15.6971L21.0039 40.3716C20.2423 41.2095 19.0076 41.2095 18.2461 40.3715V40.3715Z" />
        <a v-if="icon == 'check-circle'">
            <path :fill="color" d="M45 25C45 13.9543 36.0457 5 25 5C13.9543 5 5 13.9543 5 25C5 36.0457 13.9543 45 25 45C36.0457 45 45 36.0457 45 25Z" />
            <path d="M15.832 24.7835L23.0465 30.7249L34.1654 18.3329" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </a>
        <path v-if="icon == 'bullet'" :fill="color" d="M25 5C13.9543 5 5 13.9543 5 25C5 36.0457 13.9543 45 25 45C36.0457 45 45 36.0457 45 25C45 13.9543 36.0457 5 25 5ZM31.4516 25C31.4516 28.5574 28.5574 31.4516 25 31.4516C21.4426 31.4516 18.5484 28.5574 18.5484 25C18.5484 21.4426 21.4426 18.5484 25 18.5484C28.5574 18.5484 31.4516 21.4426 31.4516 25Z" />
        <path v-if="icon == 'chevron'" :fill="color" d="M37.1143 27.6243L19.9981 44.619C18.815 45.7937 16.902 45.7937 15.7316 44.619L12.8873 41.7949C11.7042 40.6203 11.7042 38.7209 12.8873 37.5587L25.0197 25.5125L12.8873 13.4663C11.7042 12.2916 11.7042 10.3922 12.8873 9.23008L15.719 6.38097C16.902 5.20634 18.815 5.20634 19.9855 6.38097L37.1017 23.3757C38.2973 24.5503 38.2973 26.4497 37.1143 27.6243V27.6243Z" />
        <a v-if="icon == 'chevron-circle'">
            <path :fill="color" d="M45 25C45 13.9543 36.0457 5 25 5C13.9543 5 5 13.9543 5 25C5 36.0457 13.9543 45 25 45C36.0457 45 45 36.0457 45 25Z" />
            <path fill="white" d="M31.5913 25.956L23.6915 33.6035C23.1454 34.1322 22.2625 34.1322 21.7223 33.6035L20.4095 32.3327C19.8635 31.8042 19.8635 30.9494 20.4095 30.4264L26.0091 25.0057L20.4095 19.5849C19.8635 19.0562 19.8635 18.2015 20.4095 17.6785L21.7165 16.3964C22.2625 15.8679 23.1454 15.8679 23.6856 16.3964L31.5854 24.044C32.1372 24.5727 32.1372 25.4273 31.5913 25.956Z" />
        </a>
        <path v-if="icon == 'heart'" :fill="color" d="M41.1175 9.39318C36.8363 5.7448 30.4692 6.40104 26.5396 10.4557L25.0006 12.0416L23.4615 10.4557C19.5397 6.40104 13.1649 5.7448 8.8837 9.39318C3.97754 13.5806 3.71973 21.0961 8.11027 25.6351L23.2272 41.2442C24.2037 42.252 25.7896 42.252 26.7662 41.2442L41.8831 25.6351C46.2814 21.0961 46.0236 13.5806 41.1175 9.39318V9.39318Z" />
        <!-- <path v-if="icon == 'triangle'" :fill="color" d="M22.7742 9.94643C23.5467 8.62751 25.4533 8.62751 26.2258 9.94643L43.2365 38.9892C44.0175 40.3225 43.0559 42 41.5108 42H7.48924C5.94408 42 4.98254 40.3225 5.76347 38.9892L22.7742 9.94643Z" /> -->
        <path v-if="icon == 'triangle'" :fill="color" d="M0.732177 2.66457C0.741732 1.13609 2.39281 0.182835 3.72129 0.9388L32.9744 17.5852C34.3174 18.3494 34.3234 20.2829 32.9853 21.0555L3.52177 38.0663C2.18362 38.8388 0.51215 37.8668 0.521808 36.3217L0.732177 2.66457Z" />
        <path v-if="icon == 'kettlebell'" :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M14.6095 5.51448C10.3442 6.89866 8 9.92421 8 14.0449C8 16.6161 8.80501 18.4612 10.6844 20.1967C11.9081 21.3268 11.98 21.483 11.5273 22.0274C10.3791 23.4087 8.73555 27.1567 8.39403 29.1723C7.87024 32.2667 8.39979 35.1161 10.0921 38.3084C11.6713 41.287 14.5424 44 16.7663 44.6152C18.6189 45.128 31.3682 45.1283 33.2615 44.6161C34.2023 44.3611 35.3458 43.6064 36.7769 42.2951C40.5713 38.8187 42.3984 33.853 41.606 29.1723C41.2644 27.1567 39.6209 23.4087 38.4727 22.0274C38.02 21.483 38.0919 21.3268 39.3156 20.1967C41.2021 18.4546 42 16.618 42 14.0183C42 10.3726 39.9014 7.29101 36.432 5.84269C34.7224 5.12901 34.3138 5.09835 25.5082 5.02388C17.4009 4.95505 16.1612 5.01105 14.6095 5.51448ZM33.7931 9.21272C36.3812 9.69049 38.3646 12.8387 37.5874 15.2347C37.19 16.459 35.38 18.3986 34.6343 18.3986C34.4138 18.3986 33.5651 18.0538 32.7485 17.6323C28.3369 15.3552 21.6631 15.3552 17.2515 17.6323C15.3599 18.6085 15.1851 18.5979 13.8858 17.4249C12.416 16.098 11.9444 14.5492 12.4655 12.762C12.9069 11.2474 14.666 9.45896 15.9359 9.23306C17.1786 9.01217 32.612 8.99433 33.7931 9.21272ZM24.3224 18.6871C25.0623 18.8848 24.9739 18.9414 23.6217 19.1357C18.4295 19.8829 13.899 23.8718 12.3777 29.0365L11.7787 31.0702L11.8902 28.2543C12.0111 25.1994 12.4072 24.1825 14.331 21.9876C16.5346 19.4736 21.3324 17.8889 24.3224 18.6871Z" />
    </svg>
</template>

<script>
    export default {
        name: 'Newsletter SVG Icon',

        props: {
            icon: String,
            color: String,
        },
    }

</script>